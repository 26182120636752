import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

const variants = {
  open: {
    y: 0,
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export default function MenuItem({ index, link, handleToggle }) {
  return (
    <motion.li
      variants={ variants } >
      <NavLink 
        key={ link.id }
        data-testid={ link.id }
        onClick={ handleToggle }
        className='mobile-nav-link'
        to={ link.path }>
        { link.text }
      </NavLink>
    </motion.li>
  );
};
