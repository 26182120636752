import React from 'react'
import '../../styles/containers/landing/sub-hero.scss'

export default function SubHero() {
  return (
    <div
      className='landing-sub-hero'
      data-aos='fade-up'
      data-aos-duration='600'
      data-aos-mirror='false'>
      <div 
        className='landing-sub-hero-top'>
        We are a group of passionate technologists and innovators
      </div>

      <div
        className='landing-sub-hero-bottom'
        data-aos='fade-up'
        data-aos-duration='900'
        data-aos-mirror='false'>
        helping partners achieve integrated builds
        on large scale infrastructure projects.
      </div>
    </div>
  )
}
