/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import '../../styles/containers/expertise/count-up.scss'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { tween } from 'popmotion'

const variants = {
  initial: {
    opacity: 0,
    scale: 0.5,
  },
  animated: {
    opacity: 1,
    scale: 1,
  }
}

export default function CountUp({ shouldAnimate, number, symbol, copy, icon, index }) {
  const [ currentNumber, setCurrentNumber ] = useState(0);

  useEffect(() => {
    if (shouldAnimate) {
      tween({ to: number, duration: 1000 }).start(v => setCurrentNumber(Math.round(v)));
    } 
  }, [ shouldAnimate ]);

  return (
    <motion.div 
      className='col'
      variants={ variants }
      transition={{ delay: (index + 1) * .12, duration: (index + 1) * .12 }}
      initial='initial'
      animate={ shouldAnimate ? 'animated' : 'initial' }>
      <div>
        <span 
          className='stats'>
          { currentNumber }
        </span>

        <span className='stats-symbol'>{ symbol }</span>
      </div>

      <span className='copy'>{ copy }</span>

      <motion.img 
        variants={ variants }
        transition={{ delay: (index + 1) * .16, duration: (index + 1) * .14 }}
        initial='initial'
        animate={ shouldAnimate ? 'animated' : 'initial' }
        src={ icon }
        alt='icon' />
    </motion.div>
  )
}

CountUp.propTypes = {
  shouldAnimate: PropTypes.bool,
  number: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}
