import React from 'react'
import SubPage from './SubPage'
import QuadSection from './QuadSection'
import sectionImage from '../../img/specialties/telephony.png'
import sectionImageWebp from '../../img/specialties/telephony.webp'
import phoneIcon from '../../img/specialties/icon-phone.png'
import expertiseIcon from '../../img/specialties/icon-expertise.png'
import Picture from '../../components/Picture';

const quadSections = [
  {
    title: 'EFFECTIVE IMPLEMENTATION',
    copy: 'Demonstrated expertise in the implementation of telephony devices into the overall technology package',
    icon: phoneIcon
  },
  {
    title: 'ONSITE SUPPORT',
    copy: 'Highly skilled team to support the integration of IoT solutions',
    icon: expertiseIcon
  }
]

export default function Support() {
  return (
    <SubPage>
      <div className='specialties-page-header'>
        Supporting the Design Team
      </div>

      <div className='specialties-page-header-below'>
        Developing new buildings requires engaging architects and engineers to design, permit, and supervise the construction of the facility. These organizations are experts in design. Their experience with implementation draws from experience managing contractors and sub-contractors who construct the facilities and build out the required building systems. The model is not optimized to deliver an integrated technology package but rather discrete independent building systems. This minimizes the risk of one trade failure impacting another.
      </div>

      <div className='specialties-quad'>
        { quadSections.map((section, index) => 
          <QuadSection 
            key={ index } 
            section={ section } 
            index={ index } />
        ) }
      </div>

      <Picture 
        styleClass='specialties-page-image' 
        img={ sectionImage } 
        webp={ sectionImageWebp }
        alt='City Wi-Fi' />
    </SubPage>
  )
}
