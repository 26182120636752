import React from 'react'

export default function ServiceRowImage({ image }) {
  return (
    <div
      className='services-row-image'
      style={{ backgroundImage: `url(${ image })` }}>
      &nbsp;
    </div>
  )
}
