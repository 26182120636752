import React from 'react'
import '../../styles/containers/specialties/quad-section.scss'
import { motion } from 'framer-motion'

const iconVariants = {
  static: {
    scale: 1,
    opacity: 1
  },
  initial: {
    scale: 0,
    opacity: 0
  }
}

const iconBoxVariants = {
  static: {
    borderRadius: '0%',
    background: '#19444b',
  },
  initial: {
    borderRadius: '30%',
    background: '#de592e'
  },
}

export default function QuadSection({ section, index }) {
  return (
    <div className='specialties-quad-section'>
      <motion.div
        animate='static'
        initial='initial'
        variants={ iconBoxVariants }
        transition={{ duration: (index + 1) * .12, delay: (index + 1) * .13 }}
        className='specialties-quad-section-icon'>
        <motion.img 
          animate='static'
          initial='initial'
          variants={ iconVariants }
          transition={{ duration: (index + 1) * .12, delay: (index + 1) * .13 }}
          src={ section.icon }
          alt='specialty' />
      </motion.div>

      <div className='specialties-quad-section-body'>
        <div className='specialties-quad-section-title'>
          { section.title }
        </div>

        <div className='specialties-quad-section-copy'>
          { section.copy }
        </div>
      </div>
    </div>
  )
}
