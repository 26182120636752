import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './containers/App';
import * as serviceWorker from './utils/serviceWorker';
import Inspect from 'inspx';

render(
  <React.StrictMode>
    <Router>
      <Inspect
        disabled={
          process.env.NODE_ENV === 'production'
        }>
        <App />
      </Inspect>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
