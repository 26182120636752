import React, { useState, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import useVisibilitySensor from '@rooks/use-visibility-sensor'
import { useInterval } from '../../hooks/useInterval'
import HeroCopyBox from './HeroCopyBox'
import vikingsHero from '../../img/hero/vikings.png'
import vikingsHeroWebp from '../../img/hero/vikings.webp'
import cncHero from '../../img/hero/cnc.png'
import cncHeroWebp from '../../img/hero/cnc.webp'
// import allegiantHero from '../../img/hero/allegiant-stadium.png'
// import allegiantHeroWebp from '../../img/hero/allegiant-stadium.webp'
// import sofiHero from '../../img/hero/sofi-stadium.png'
// import sofiWebp from '../../img/hero/sofi-stadium.webp'
import fansHero from '../../img/hero/fans.png'
import fansHeroWebp from '../../img/hero/fans.webp'
import twelthManHero from '../../img/hero/12ML.png'
import twelthManHeroWebp from '../../img/hero/12ML.webp'

const images = [
  { img: vikingsHero, webp: vikingsHeroWebp },
  { img: cncHero, webp: cncHeroWebp },
  // { img: allegiantHero, webp: allegiantHeroWebp },
  // { img: sofiHero, webp: sofiWebp },
  { img: fansHero, webp: fansHeroWebp },
  { img: twelthManHero, webp: twelthManHeroWebp }
]

const swipeConfidenceThreshold = 1000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

const variants = {
  enter: (direction) => {
    return {
      zIndex: 0,
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  }
}

export default function MotionHero() {
  const rootNode = useRef(null)
  const { isVisible } = useVisibilitySensor(rootNode, {
    intervalCheck: true,
  })
  const [ [ page, direction ], setPage ] = useState([ 0, 0 ])

  const imageIndex = wrap(0, images.length, page)

  const paginate = (newDirection) => {
    setPage([ page + newDirection, newDirection ])
  };

  useInterval(() => {
    if (isVisible) {
      paginate(1);
    }
  }, 7000);

  const handleDragEnd = (e, { offset, velocity }) => {
    const swipe = swipePower(offset.x, velocity.x);

    if (swipe < -swipeConfidenceThreshold) {
      paginate(1);
    } else if (swipe > swipeConfidenceThreshold) {
      paginate(-1);
    }
  }

  return (
    <div className='motion-hero-wrapper'>
      <div ref={ rootNode } className='motion-hero'>
        <picture>
          <source srcSet={ images[ imageIndex ].webp } />
          <AnimatePresence initial={ false } custom={ direction }>
            <motion.img
              key={ page }
              src={ images[ imageIndex ].img }
              custom={ direction }
              variants={ variants }
              initial='enter'
              animate='center'
              exit='exit'
              transition={{
                x: { 
                  type: 'spring', 
                  stiffness: 350, 
                  damping: 125,
                  duration: 0.25
                },
                opacity: { 
                  duration: 0.25
                }
              }}
              drag='x'
              dragConstraints={{ 
                left: 0, 
                right: 0 
              }}
              dragElastic={ 1 }
              onDragEnd={ handleDragEnd } />
          </AnimatePresence>
        </picture>
      </div>

      <HeroCopyBox />
    </div>
  )
}
