import React from 'react'
import '../../styles/containers/landing/mti.scss'
import { useMediaQuery } from 'react-responsive'
import WidthLimiter from '../../components/WidthLimiter'
import sectionBackground from '../../img/landing/mti-bg.png'

export default function MTI() {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  return (
    <div 
      className='landing-mti'
      style={{ backgroundImage: `url( ${ sectionBackground } )` }}
      data-aos='fade-in'
      data-aos-duration='900'
      data-aos-mirror='true'
      data-aos-disable={ isMobile }>
      <WidthLimiter>
        <div className='landing-mti-box'>
          <div 
            className='landing-mti-box-title'
            data-aos='fade-in'
            data-aos-duration='600'
            data-aos-disable={ isMobile }>
            MASTER TECHNOLOGY INTEGRATORS
          </div>

          <div 
            className='landing-mti-box-copy'
            data-aos='fade-in'
            data-aos-duration='900'
            data-aos-disable={ isMobile }>
            Leveraging concepts drawn from lean construction methodologies, 
            AmpThink focuses on the role of a Master Technology Integrator 
            (“MTI”). The premise of the MTI role? Technology should be 
            delivered on a unified platform; one structured cabling plant, 
            one network, and a common compute platform. All for easy 
            operations and a premium guest experience.
          </div>
        </div>
      </WidthLimiter>
    </div>
  )
}
