import React from 'react'
import '../../styles/containers/landing/join-our-team.scss'
import { useMediaQuery } from 'react-responsive'
import careerImage from '../../img/landing/career.png'
import careerImageWebp from '../../img/landing/career.webp'
import Picture from '../../components/Picture'

export default function JoinOurTeam() {
  const isTablet = useMediaQuery({ query: '(max-width: 800px)' })

  return (
    <div 
      className='landing-careers'
      data-aos='fade-out'
      data-aos-anchor-placement='top-center'
      data-aos-mirror='true'
      data-aos-duration='600'
      data-aos-disable={ isTablet }>
      <div className='landing-careers-left'>
        <Picture 
          img={ careerImage } 
          webp={ careerImageWebp }
          alt='AmpThink' />
      </div>

      <div 
        className='landing-careers-right'>
        <div className='landing-careers-heading'>
          Join our team
        </div>

        <div className='landing-careers-copy'>
          The future of venue technology is happening today. 
          If you’re interested in joining the future, look no further. 
          We’re always looking for the best and brightest to work with 
          because that’s what our clients demand. That’s what we demand. 
          Our philosophy is simple, hire the best and produce the best. 
          So, if you think you have what it takes to join our growing 
          team, let us hear from you.
        </div>
      </div>
    </div>
  )
}
