import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import ServiceRowImage from './ServiceRowImage'

export default function ServiceRow({ index, imageImport, imageRight, title, copy }) {
  const isTablet = useMediaQuery({ query: '(max-width: 800px)' });

  return  (
    <div className='services-row'>
      { (isTablet || !imageRight) && (
        <div 
          data-aos={ index ? 'slide-right' : '' }
          data-aos-mirror='true'
          data-aos-duration='300'
          className='services-row-side'>
          <ServiceRowImage image={ imageImport } />
        </div>
      ) }

      <div 
        data-aos={ index ? (imageRight ? 'slide-right' : 'slide-left') : '' }
        data-aos-mirror='true'
        data-aos-duration='300'
        className='services-row-side'>
        <div className='services-row-title'>
          { title }
        </div>

        <div className='services-row-copy'>
          { copy }
        </div>
      </div>

      { !isTablet && imageRight && (
        <div 
          data-aos={ index ? 'slide-left' : '' }
          data-aos-mirror='true'
          data-aos-duration='300'
          className='services-row-side'>
          <ServiceRowImage image={ imageImport } />
        </div>
      ) }
    </div>
  )
}

ServiceRow.propTypes = {
  image: PropTypes.string.isRequired,
  imageRight: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired
}
