import React, { useState, useRef, useEffect } from 'react';
import '../../styles/containers/expertise/donut.scss';
import { Doughnut } from 'react-chartjs-2';
import is from 'is_js';
import { motion, AnimatePresence } from 'framer-motion';

const donutData = {
  datasets: [{
    label: 'Stadiums',
    data: [ 14, 24, 13, 10, 4, 12, 2 ],
    backgroundColor: [
      'rgba(237, 127, 60, 1)',
      'rgba(229, 77, 7, 1)',
      'rgba(171, 59, 5, 1)',
      'rgba(144, 51, 4, 1)',
      'rgba(115, 42, 2, 1)',
      'rgba(65, 25, 4, 1)',
      'rgba(36, 16, 4, 1)',
    ],
    hoverBackgroundColor: [
      'rgba(237, 127, 60, 1)',
      'rgba(229, 77, 7, 1)',
      'rgba(171, 59, 5, 1)',
      'rgba(144, 51, 4, 1)',
      'rgba(115, 42, 2, 1)',
      'rgba(65, 25, 4, 1)',
      'rgba(36, 16, 4, 1)',
    ],
    hoverBorderColor: [
      'rgba(237, 127, 60, 1)',
      'rgba(229, 77, 7, 1)',
      'rgba(171, 59, 5, 1)',
      'rgba(144, 51, 4, 1)',
      'rgba(115, 42, 2, 1)',
      'rgba(65, 25, 4, 1)',
      'rgba(36, 16, 4, 1)',
    ],
    borderAlign: 'inner',
    borderColor: '#fff',
    borderWidth: 3,
    hoverBorderWidth: 6
  }],
  labels: [
    'NFL',
    'MLB',
    'NBA',
    'NHL',
    'MLS',
    'NCAA',
    'PanAm'
  ]
}

const empty = {
  datasets: [{
    label: 'empty',
    data: []
  }]
}

const legend = {
  open: {
    opacity: 1,
    transition: {
      staggerChildren: 0.09,
      staggerDirection: -1
    }
  },
  closed: {
    opacity: 0,
  }
}

const legendItem = {
  open: {
    x: 0,
    transition: {
      duration: 0.25,
    }
  },
  closed: {
    x: 1000,
  }
}

export default function VenueDoughnut({ chartVisible }) {
  const chartRef = useRef();
  const [ dataState, setDataState ] = useState(false);
  const [ hovered, setHovered ] = useState();

  const donutOptions = {
    cutoutPercentage: 82,
    tooltips: {
      displayColors: false,
      caretPadding: 0,
      caretSize: 0,
      cornerRadius: 0,
      xPadding: 0,
      yPadding: 0,
      bodySpacing: 0,
      mode: 'index',
      callbacks: {
        label() {
          return '';
        },
      },
    },
    onHover(evt, item) {
      if (item.length) {
        setHovered(item[0]._index);
      } else {
        if (is.not.undefined(hovered)) {
          setHovered();
        }
      }
    }
  }

  useEffect(() => {
    if (chartVisible && !dataState) {
      setDataState(donutData);
    }
  }, [ chartVisible, dataState ])

  useEffect(() => {
    if (is.undefined(hovered)) {
      let dataset = chartRef.current.chartInstance.data.datasets[0];

      dataset.backgroundColor = [
        'rgba(237, 127, 60, 1)',
        'rgba(229, 77, 7, 1)',
        'rgba(171, 59, 5, 1)',
        'rgba(144, 51, 4, 1)',
        'rgba(115, 42, 2, 1)',
        'rgba(65, 25, 4, 1)',
        'rgba(36, 16, 4, 1)',
      ];
      
      chartRef.current.chartInstance.update();
    } else {
      let dataset = chartRef.current.chartInstance.data.datasets[0];

      dataset.backgroundColor = [
        'rgba(237, 127, 60, .25)',
        'rgba(229, 77, 7, .25)',
        'rgba(171, 59, 5, .25)',
        'rgba(144, 51, 4, .25)',
        'rgba(115, 42, 2, .25)',
        'rgba(65, 25, 4, .25)',
        'rgba(36, 16, 4, .25)',
      ];

      dataset.backgroundColor[ hovered ] = donutData.datasets[ 0 ].backgroundColor[ hovered ];
      chartRef.current.chartInstance.update();
    }
  }, [ hovered ])

  const handleHover = (index) => {
    if (is.object(dataState)) {
      let meta = chartRef.current.chartInstance.getDatasetMeta(0);
      let rectangle = chartRef.current.chartInstance.canvas.getBoundingClientRect();
      let point = meta.data[index].getCenterPoint();

      let mouseMoveEvent = new MouseEvent('mousemove', {
        clientX: rectangle.left + point.x,
        clientY: rectangle.top + point.y
      });

      chartRef.current.chartInstance.canvas.dispatchEvent(mouseMoveEvent);
    }
  }

  const handleUnHover = () => {
    if (is.object(dataState)) {
      let mouseOutEvent = new MouseEvent('mouseout');
      chartRef.current.chartInstance.canvas.dispatchEvent(mouseOutEvent);
    }
  }

  return (
    <div className='donut'>
      <div className='donut-hole-wrapper'>
        <Doughnut 
          ref={ chartRef }
          data={ dataState || empty }
          legend={{ display: false }}
          options={ donutOptions } />
      
        <div className='donut-hole'>
          { is.object(dataState) && (
            <div className='donut-hole-large'>
              75+
            </div>
          ) }
          
          { is.object(dataState) && (
            <div className='donut-hole-small'>
              Sports & Entertainment Venues
            </div>
          ) }

          <AnimatePresence>
            { is.not.undefined(hovered) && (
              <motion.div 
                className='donut-hovered' 
                key={ `hoverd-${ hovered }` }
                initial={{ scale: 0.5, y: 10 }}
                animate={{ scale: 1, y: 0 }}
                exit={{ display: 'none', scale: 0, y: -10 }}
                transition={{ ease: 'linear', duration: .12 }}>
                { donutData.datasets[0].data[ hovered ] } { donutData.labels[ hovered ] } Stadiums
              </motion.div>
            ) }
          </AnimatePresence>
        </div>
      </div>
      
      { is.object(dataState) && (
        <motion.div 
        className='donut-legend'
        variants={ legend }
        initial='closed'
        animate='open'>
        { dataState.labels.map((label, i) =>
          <motion.div 
            className={ (is.undefined(hovered) || hovered === i) ? 'donut-legend-row' : 'donut-legend-row opacify' } 
            key={ label }
            onMouseEnter={ e => handleHover(i, e) }
            onMouseLeave={ e => handleUnHover(e) }
            variants={ legendItem }>
            <motion.div 
              className='donut-legend-dot' 
              style={{ backgroundColor: donutData.datasets[0].hoverBackgroundColor[i] }}>
            </motion.div>
            
            <div className='donut-legend-label'>
              <div className='donut-legend-key'>
                { label }
              </div>

              <div className='donut-legend-value'>
                { donutData.datasets[0].data[i] }
              </div>
            </div>
          </motion.div>
        ) }
      </motion.div>
      ) }
    </div>
  )
}