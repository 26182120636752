import React from 'react';
import '../styles/components/press.scss';
import is from 'is_js';
import WidthLimiter from './WidthLimiter';
import PressExcerpt from './PressExcerpt';

export default function Press({ posts }) {
  return (
    <div className='landing-press'>
      <WidthLimiter>
        <div 
          data-aos='fade-up'
          data-aos-mirror='false'
          data-aos-duration='300'
          className='landing-press-heading'>
          PRESS
        </div>

        <div className='landing-press-main'>
          <div 
            data-aos='fade-ups'
            data-aos-duration='300'
            data-aos-mirror='true'
            className='landing-press-main-left'>
            <div className='inquiries'>
              Media Inquiries
            </div>

            <div className='phone'>
              <a href='tel:1.800.735.6310'>1.800.735.6310</a>
            </div>

            <div className='email'>
              <a href='mailto:press@ampthink.com'>press@ampthink.com</a>
            </div>
          </div>

          <div className='landing-press-main-right'>
            { is.array(posts) && posts.map((post, index) => 
              <PressExcerpt 
                key={ post.id } 
                index={ index }
                post={ post } />
            ) }
          </div>
        </div>
      </WidthLimiter>
    </div>
  )
}
