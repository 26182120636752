import React from 'react'
import { motion } from 'framer-motion'

const variants = {
  initial: {
    x: -100,
    opacity: 0
  },
  static: {
    x: 0,
    opacity: 1
  }
}

export default function SubPage({ children }) {
  return (
    <motion.div
      variants={ variants }
      initial='initial'
      animate='static'
      transition={{ duration: 0.2 }}>
      { children }
    </motion.div>
  )
}
