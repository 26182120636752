import React, { useEffect } from 'react'
import '../styles/containers/landing.scss'
import is from 'is_js'
import { usePosts } from '../hooks/use-posts'

import MotionHero from './landing/MotionHero'
import SubHero from './landing/SubHero'
import MTI from './landing/MTI'
import CaseStudies from './landing/CaseStudies'
import Press from '../components/Press'
import LandingButton from './landing/LandingButton'
import JoinOurTeam from './landing/JoinOurTeam'

export default function Landing() {
  const { data: posts } = usePosts()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ ])

  return (
    <div className='landing' id='landing'>
      <MotionHero />
      <SubHero />
      <MTI />
      <CaseStudies />
      <Press posts={ posts } />

      { is.array(posts) && (
        <LandingButton 
          text='PRESS'
          path='/news' />
      ) }

      <JoinOurTeam />
      <LandingButton
        text='CAREERS'
        external='https://apply.workable.com/ampthink/' />
    </div>
  )
}
