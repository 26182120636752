import React from 'react'
import '../styles/components/sub-page-hero.scss'

export default function SubPageHero({ image, title, copy, isThin = false }) {
  return (
    <div className='sub-page-hero'>
      <div
        className={ isThin ? 'sub-page-hero-image thin' : 'sub-page-hero-image' }
        style={{ 
          backgroundImage: `url(${ image })`
        }} >
        <div className='sub-page-hero-title-wrapper'>
          <div
            data-aos='slide-left'
            data-aos-duration='500'
            data-aos-mirror='false'
            className='sub-page-hero-title'>
            { title }
          </div>
        </div>
      </div>
      
      { copy && 
        <div 
          data-aos='slide-right'
          data-aos-duration='400'
          data-aos-mirror='false'
          className='sub-page-hero-copy'>
          <div className='sub-page-hero-copy-inner'>
            { copy }
          </div>
        </div>
      }
    </div>
  )
}
