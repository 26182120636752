import React from 'react';

export default function OfficeInfo({ title, children }) {
  return (
    <>
      <div className='contact-offices-title'>
        { title }
      </div>

      <div className='contact-offices-address'>
        { children }
      </div>
    </>
  )
}
