import React from 'react'
import SubPage from './SubPage'
import QuadSection from './QuadSection'
import Picture from '../../components/Picture'
import sectionImage from '../../img/specialties/specialties-iot.png'
import sectionImageWebp from '../../img/specialties/specialties-iot.webp'
import iotintegrationIcon from '../../img/specialties/icon-iotintegration.png'
import expertiseIcon from '../../img/specialties/icon-expertise.png'

const quadSections = [
  {
    title: 'END-TO-END INTEGRATION',
    copy: 'Unify IoT and technology networks into a cohesive network for efficient management and optimization',
    icon: iotintegrationIcon
  },
  {
    title: 'ONSITE SUPPORT',
    copy: 'Highly skilled team to support the integration of IoT solutions',
    icon: expertiseIcon
  }
]

export default function IOT() {
  return (
    <SubPage>
      <div className='specialties-page-header'>
        IoT (Internet of Things)
      </div>

      <div className='specialties-page-header-below'>
        While AmpThink doesn’t specifically deliver IOT solutions, IOT devices – 
        principally sensors – require network connectivity and often on-premise 
        compute. The AmpThink team has been heavily involved in integrating IOT 
        devices at projects including US Bank Stadium, Dickies Arena, LA Stadium 
        (So-Fi Stadium), and Las Vegas Stadium (Allegiant Stadium). The AmpThink 
        team has assisted with the development of networking topologies, the 
        development of addressing, ensuring the use of IT best practices, and 
        onboarding these technologies.
      </div>

      <div className='specialties-quad'>
        { quadSections.map((section, index) => 
          <QuadSection 
            key={ index } 
            section={ section } 
            index={ index } />
        ) }
      </div>

      <Picture 
        styleClass='specialties-page-image' 
        img={ sectionImage } 
        webp={ sectionImageWebp }
        alt='City Wi-Fi' />
    </SubPage>
  )
}
