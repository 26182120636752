import React from 'react'
import '../styles/components/cross-site-header.scss'
import SocialLinks from './SocialLinks'
import WidthLimiter from './WidthLimiter'
import ampthinkLogo from '../img/components/ampthink-logo-grey.svg'
import rapidtoolLogo from '../img/components/rapidtool-logo-grey.svg'
import ampboardsLogo from '../img/components/ampboards-logo-grey.svg'

export default function CrossSiteHeader() {
  return (
    <div 
      id='home' 
      className='csh'>
      <WidthLimiter>
        <div className='csh-inner'>
          <div className='csh-inner-left'>
            <a 
              href='https://www.ampthink.com/' 
              target='_blank' 
              rel='noopener noreferrer'>
              <img 
                src={ ampthinkLogo } 
                alt='AmpThink' 
                className='ampthink' />
            </a>

            <a 
              style={{ display: 'none' }}
              href='https://www.rapidtooling.com'
              target='_blank' 
              rel='noopener noreferrer'>
              <img 
                src={ rapidtoolLogo }
                alt='RapidTool' 
                className='rapidtool' />
            </a>

            <a 
              href='https://www.ampboards.com'
              target='_blank' 
              rel='noopener noreferrer'>
              <img 
                src={ ampboardsLogo }
                alt='AmpBoards' 
                className='ampboards' />
            </a>
          </div>

          <div
            className='csh-inner-right'>
            <SocialLinks />
          </div>
        </div>
      </WidthLimiter>
    </div>
  )
}