import React from 'react'
import '../../styles/containers/landing/hero-copy.scss'
import { useMediaQuery } from 'react-responsive'

export default function HeroCopyBox() {
  const shouldBreak = useMediaQuery({ query: '(min-width: 800px)' })

  return (
    <div className='landing-hero-copy'>
      <hr/>
      <h1>
        BUILDING { shouldBreak ? <br/> : null }
        CARRIER CLASS { shouldBreak ? <br/> : null }
        INFRASTRUCTURE { shouldBreak ? <br/> : null }
        FOR LARGE PUBLIC { shouldBreak ? <br/> : null }
        VENUES 
      </h1>
    </div>
  )
}
