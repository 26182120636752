import React from 'react'
import SubPageHeader from '../components/SubPageHero'
import expertiseHero from '../img/expertise/header-expertise.png'

export default function PageNotFound() {
  return (
    <div className='pnf'>
      <SubPageHeader
        title='Page not found'
        image={ expertiseHero }
        isThin={ true } />

      <div className='pnf-body'>
        404
      </div>
    </div>
  )
}
