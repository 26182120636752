import React, { useEffect } from 'react'
import '../styles/app.scss'
import ReactGA from 'react-ga'
// import ScrollProgress from '../components/ScrollProgress'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Routes from './Routes'

export default function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-72166031-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [])
  
  return (
    <div className='app'>
      <NavBar />

      <div>
        <Routes />
      </div>

      <Footer />
    </div>
  );
}
