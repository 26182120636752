import React, { useEffect } from 'react'
import '../../styles/containers/news/press-post.scss'
import is from 'is_js'
import parse from 'html-react-parser'
import { format, parseISO } from 'date-fns'
import { Link, useParams } from 'react-router-dom'
import { usePost } from '../../hooks/use-post'
import fbIcon from '../../img/components/fb-icon.png'
import twitterIcon from '../../img/components/twitter-icon.png'
import linkedinIcon from '../../img/components/linkedin-icon.png'
import fbIconWebp from '../../img/components/fb-icon.webp'
import twitterIconWebp from '../../img/components/twitter-icon.webp'
import linkedinIconWebp from '../../img/components/linkedin-icon.webp'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import SocialShareButton from '../../components/SocialShareButton'
import LogoLoading from '../../components/LogoLoading'

export default function PressPost() {
  const { post_id } = useParams()
  const { data: post } = usePost(post_id)
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ post_id ])

  return (
    <div className='press-post'>
      <div className='sub-page-hero'>
        <div
          className='sub-page-hero-image thin'
          style={{ backgroundColor: '#3d3d3d' }} >
          <div className='sub-page-hero-title-wrapper'>
            { is.object(post) ? (
              <div
                data-aos='slide-left'
                data-aos-duration='500'
                data-aos-mirror='false'
                className='sub-page-hero-title'>
                <Link 
                  className='press-post-back'
                  to='/news'>
                  Back to News
                </Link>
                { `News - ${ post && format(parseISO(post.date), 'MM.dd.yyyy') }` }
              </div>
            ) : null }
          </div>
        </div>
      </div>

      { is.undefined(post) ? (
        <LogoLoading />
      ) : (
        <div className='press-post-body'>
          <div className='press-post-title'>
            { parse(post.title.rendered) }
          </div>

          <div className='press-post-content'>
            { parse(post.content.rendered) }
          </div>

          <div className='press-post-social'>
            <FacebookShareButton url={ window.location.href }>
              <SocialShareButton text='SHARE' icon={ fbIcon } iconWebp={ fbIconWebp }  />
            </FacebookShareButton>

            <TwitterShareButton url={ window.location.href }>
              <SocialShareButton text='SHARE' icon={ twitterIcon } iconWebp={ twitterIconWebp }  />
            </TwitterShareButton>

            <LinkedinShareButton url={ window.location.href }>
              <SocialShareButton text='SHARE' icon={ linkedinIcon } iconWebp={ linkedinIconWebp }  />
            </LinkedinShareButton>
          </div>
        </div>
      ) }
    </div>
  )
}
