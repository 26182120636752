import React from 'react'
import SubPage from './SubPage'
import QuadSection from './QuadSection'
import Picture from '../../components/Picture'
import sectionImage from '../../img/specialties/citywifi.png'
import sectionImageWebp from '../../img/specialties/citywifi.webp'
import wifiIcon from '../../img/specialties/icon-wifi.png'
import timeIcon from '../../img/specialties/icon-time.png'
import partnerIcon from '../../img/specialties/icon-partner.png'
import innovationIcon from '../../img/specialties/icon-innovation.png'

const quadSections = [
  {
    title: 'WI-FI EXPERTS',
    copy: '',
    icon: wifiIcon
  },
  {
    title: 'ENHANCED EFFICIENCY',
    copy: '',
    icon: timeIcon
  },
  {
    title: 'CERTIFIED PARTNER',
    copy: '',
    icon: partnerIcon
  },
  {
    title: 'INNOVATIVE IMPLEMENTATION',
    copy: '',
    icon: innovationIcon
  },
]

export default function WirelessNetworking() {
  return (
    <SubPage>
      <div className='specialties-page-header'>
        Wireless Networking
      </div>

      <div className='specialties-page-header-below'>
        AmpThink has been a regular contributor to the development 
        of high-density networks for stadiums and arenas. Our work 
        is well known in the industry and we regularly contribute 
        our learnings to both the vendors we implement and their 
        competitors through our annual technology evaluation process 
        and involvement in project RFPs.  Our participation in the 
        Fort Worth Arena project as the Master Technology Integrator 
        marked our entry into the DAS construction business. While 
        we today rely on partners to execute the design of DAS systems, 
        our focus on automation and construction process are already 
        changing the economics of DAS projects. As we continue to grow 
        our capabilities in this space, we are confident that we will 
        drive new business models and transform the value of the systems 
        for our customers and partners. The opportunity to evolve 
        implementation practices directly draws from the work done 
        on Wi-Fi systems where our processes lowered costs, provided 
        measurable outcomes, and built monetizable frameworks for our clients.
      </div>

      <div className='specialties-quad'>
        { quadSections.map((section, index) => 
          <QuadSection 
            key={index } 
            section={ section } 
            index={ index } />
        ) }
      </div>

      <Picture 
        styleClass='specialties-page-image' 
        img={ sectionImage } 
        webp={ sectionImageWebp }
        alt='City Wi-Fi' />
    </SubPage>
  )
}
