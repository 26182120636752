import React, { useState } from 'react';
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import useWindowSize from '@rooks/use-window-size';
import MenuToggle from './MenuToggle';
import NavigationOverlay from './NavigationOverlay';
import logo from '../img/components/ampthink-logo-reversed.svg';

const sidebar = {
  open: (height = 1000) => ({
    background: 'rgba(25, 68, 75, 1)',
    clipPath: `circle(${ height * 2 + 200 }px at 92% 40px)`,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 40,
      restDelta: 2
    }
  }),
  closed: {
    background: 'rgba(25, 68, 75, .0)',
    clipPath: 'circle(54px at 95% 30px)',
    // clipPath: 'circle(30px at 92% 40px)',
    transition: {
      delay: 0.1,
      type: 'spring',
      stiffness: 400,
      damping: 40
    }
  }
};

export default function MobileNav({ navLinks }) {
  // const navRef = useRef();
  const [ menuOpen, setMenuOpen ] = useState(false);
  const { outerHeight } = useWindowSize();

  const handleToggle = () => setMenuOpen(menuOpen => !menuOpen);

  // useEffect(() => {
  //   if (menuOpen) {
  //     disableBodyScroll(navRef.current);
  //   } else {
  //     clearAllBodyScrollLocks();
  //   }
  // }, [ menuOpen ])

  return (
    <motion.nav
      initial={ false }
      animate={ menuOpen ? 'open' : 'closed' }
      custom={ outerHeight } >
      <motion.div 
        className='mobile-nav-background' 
        variants={ sidebar }>
        <div className={ menuOpen ? 'mobile-nav-top with-border' : 'mobile-nav-top' }>
          <NavLink
            data-testid='nav-link-landing'
            className='nav-logo'
            onClick={ handleToggle }
            to='/'>
            <motion.img
              initial={ false }
              className='mobile-nav-logo'
              style={{ left: '7%' }}
              animate={ menuOpen ? { left: '7%', opacity: 1 } : { left: '-20%', opacity: 0 } }
              transition={{ duration: .4 }}
              src={ logo } 
              alt='logo' />
          </NavLink>

          <MenuToggle 
            handleToggle={ handleToggle } />
        </div>

        <NavigationOverlay 
          handleToggle={ handleToggle }
          navLinks={ navLinks } 
          menuOpen={ menuOpen } />
      </motion.div>
    </motion.nav>
  )
}
