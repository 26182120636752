import React from 'react';
import '../styles/components/logo-loading.scss';
import loadingGif from '../img/components/AMP-logo-loading.gif';

export default function LogoLoading() {
  return (
    <div className='logo-loading'>
      <img src={ loadingGif } alt='loading' />
    </div>
  )
}
