import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Landing from './Landing'
import Expertise from './Expertise'
import Specialties from './Specialties'
import Services from './Services'
import News from './News'
import PressPost from './news/PressPost'
import Contact from './Contact'
import PageNotFound from './PageNotFound'
import PrivacyPolicy from './PrivacyPolicy'
import Support from './Support'

const baseRoutes = [
  { path: '/', comp: Landing, exact: true },
  { path: '/expertise', comp: Expertise, exact: true },
  { path: '/specialties', comp: Specialties, exact: false },
  { path: '/services', comp: Services, exact: true },
  { path: '/news', comp: News, exact: true },
  { path: '/news/:post_id', comp: PressPost, exact: true },
  { path: '/contact', comp: Contact, exact: true },
  { path: '/privacy', comp: PrivacyPolicy, exact: true },
  { path: '/support', comp: Support, exact: true },
]

export default function Routes() {
  return (
    <Switch>
      { baseRoutes.map(option => 
        <Route
          exact={ option.exact }
          key={ option.path }
          path={ option.path }
          render={ () => <option.comp /> } />
      ) }

      <Route
        key='default'
        path='/*'
        render={ () => <PageNotFound /> } />
    </Switch>
  )
}
