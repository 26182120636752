import React, { useEffect } from 'react'
import '../styles/containers/expertise.scss'
import SubPageHero from '../components/SubPageHero'
import Growth from './expertise/Growth'
import WidthLimiter from '../components/WidthLimiter'
import expertiseHero from '../img/expertise/amp-hero-expertise.png'
import constructionImage from '../img/expertise/construction.png'
import constructionImageWebp from '../img/expertise/construction.webp'
import Picture from '../components/Picture'

export default function Expertise() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ ])

  return (
    <div className='expertise'>
      <SubPageHero
        title='Expertise'
        image={ expertiseHero }
        copy='AmpThink is a recognized leader in the deployment of complex technology systems in public venues. We’ve executed projects in over 75+ stadiums and arenas, hundreds of manufacturing and distribution facilities, and orchestrated national roll-outs for fortune 500 retailers.' />
      
      <WidthLimiter>
        <div className='expertise-mti'>
          <div className='expertise-heading'>
            Master Technology Integrator
          </div>

          <div className='expertise-mti-body'>
            <p>
              In the existing Construction Manager led and Design Build 
              Models, each of the sub-contractors brings their own systems 
              to the table and executes their scopes independently. The result is:
            </p>

            <ul>
              <li>duplication of scopes</li>
              <li>increased operational costs</li>
              <li>operational complexity post construction</li>
            </ul>

            <p>
              The typical contracting approach applied to the construction 
              of large venues relies on sub-contracting complete scopes to 
              spread risk among project participants and provide project 
              managers with a single source of accountability for each building system. 
              Today, most of the sub-trades require technology to operate 
              these systems
            </p>
          </div>
        </div>
      </WidthLimiter>

      <Picture 
        styleClass='expertise-construction' 
        img={ constructionImage } 
        webp={ constructionImageWebp }
        alt='Construction' />

      <WidthLimiter>
        <div className='expertise-content-block'>
          <div className='expertise-heading'>
            Rethinking Traditional Construction Methods
          </div>

          <div className='expertise-copy'>
            Based on AmpThink's extensive experience in the sports and 
            entertainment market, we have had the opportunity to participate 
            in a wide range of project scopes under a variety of management 
            strategies. This experience has shown us that there is a better 
            way. Leveraging concepts drawn from lean construction methodologies, 
            specifically integrated project delivery, AmpThink focuses on the 
            role of a Master Technology Integrator ("MTI").
          </div>
        </div>

        <div className='expertise-content-block'>
          <div className='expertise-heading'>
            Exploring Alternative Approaches
          </div>

          <div className='expertise-copy'>
            Typical design work assumes approaches carried forward from prior 
            implementations. This approach leverages past experience to deliver 
            on the design narrative. In some cases, the evolution of technology 
            outpaces the design cycle, missing the opportunity to save money or 
            deliver unexpected outcomes. Every day, we work with leading Technology 
            Providers and OEMs providing valuable insights delivering innovation.
          </div>
        </div>
      </WidthLimiter>
      
      <Growth />
    </div>
  )
}
