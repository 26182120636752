import React from 'react';
import { motion } from "framer-motion";

function Path(props) {
  return (
    <motion.path
      fill='transparent'
      strokeWidth='3'
      strokeLinecap='round'
      { ...props }
    />
  )
}

export default function MenuToggle({ handleToggle }) {
  return (
    <button 
      className='menu-toggle' 
      onClick={ handleToggle }>
      <svg width='23' height='23' viewBox='0 0 23 23'>
        <Path
          variants={{
            closed: { d: 'M 2 2.5 L 20 2.5', stroke: '#103e45' },
            open: { d: 'M 3 16.5 L 17 2.5', stroke: '#ffffff' }
          }}
        />
        <Path
          d='M 2 9.423 L 20 9.423'
          variants={{
            closed: { opacity: 1, stroke: '#103e45' },
            open: { opacity: 0, stroke: '#ffffff' }
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: 'M 2 16.346 L 20 16.346', stroke: '#103e45' },
            open: { d: 'M 3 2.5 L 17 16.346', stroke: '#ffffff' }
          }}
        />
      </svg>
    </button>
  )
}
