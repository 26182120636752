import { useQuery } from 'react-query';
import axios from 'axios';
import adapter from 'axios/lib/adapters/http';

// const proxy = process.env.NODE_ENV === 'test' ? '' : 'https://protected-shore-15426.herokuapp.com/';
const proxy = '';

export const usePosts = () => {
  return useQuery('get-posts', () => axios.get(`${ proxy }https://blog.ampthink.com/wp-json/wp/v2/posts?per_page=3`, { adapter })
    .then(res => {
      return res.data
    })
    .catch(err => console.error(err)),
    // {
    //   refetchOnMount: false,
    // }
  );
}
