import React from 'react';
import '../../styles/containers/contact/contact-info.scss';

const contactBlocks = [
  {
    title: 'TELEPHONE',
    copy: <a href='tel:1.800.735.6310'>1.800.735.6310</a>
  },
  {
    title: 'GENERAL INFORMATION',
    copy: <a href='mailto:info@ampthink.com'>info@ampthink.com</a>
  },
  {
    title: 'TECHNICAL SUPPORT',
    copy: <a href='mailto:support@ampthink.com'>support@ampthink.com</a>
  }
]

export default function ContactInfo() {
  return (
    <div className='contact-info'>
      { contactBlocks.map(block => 
        <div className='contact-info-block' key={ block.title }>
          <div className='contact-info-title'>
            { block.title }
          </div>

          <div className='contact-info-copy'>
            { block.copy }
          </div>
        </div>
      ) }
    </div>
  )
}
