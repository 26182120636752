import React from 'react'
import SubPage from './SubPage'
import QuadSection from './QuadSection'
import Picture from '../../components/Picture'
import sectionImage from '../../img/specialties/wirednetworking.png'
import sectionImageWebp from '../../img/specialties/wirednetworking.webp'
import intergationIcon from '../../img/specialties/icon-intergation.png'
import checkIcon from '../../img/specialties/icon-check.png'
import integrationIcon from '../../img/specialties/icon-integration.png'
import fansIcon from '../../img/specialties/icon-fans.png'

// import SlideViewer from '../../components/SlideViewer'
// import iptvImage from '../../img/specialties/specialties-iptv.png'
// import iotImage from '../../img/specialties/specialties-iot.png'
// const slides = [
//   {
//     image: sectionImage,
//     text: 'Network Room'
//   },
//   {
//     image: iptvImage,
//     text: 'IPTV'
//   },
//   {
//     image: iotImage,
//     text: 'IOT'
//   }
// ]

const quadSections = [
  {
    title: 'ENTERPRISE SOLUTIONS',
    copy: '',
    icon: intergationIcon
  },
  {
    title: 'RELIABLE NETWORKS',
    copy: '',
    icon: checkIcon
  },
  {
    title: 'SEAMLESS INTEGRATION',
    copy: '',
    icon: integrationIcon
  },
  {
    title: 'FAN FIRST APPROACH',
    copy: '',
    icon: fansIcon
  },
]

export default function WiredNetworking() {
  return (
    <SubPage>
      <div className='specialties-page-header'>
        Wired Networking
      </div>

      <div className='specialties-page-header-below'>
        While AmpThink may be best known for our Wi-Fi implementations, it is our core wired networking 
        skills that differentiate our practice from other wireless integrators. In sports and entertainment, 
        AmpThink regularly develops networks capable of supporting the largest enterprises in the world. 
        The same networks must service business constituents that include teams, venue operators, 
        concessionaires, merchandise vendors, sponsors, building management systems, broadcasters, 
        and other 3rd parties that depend on IP based transport to deliver critical venue services. 
      </div>

      <div className='specialties-quad'>
        { quadSections.map((section, index) => 
          <QuadSection 
            key={ index } 
            section={ section } 
            index={ index } />
        ) }
      </div>

      <Picture 
        styleClass='specialties-page-image' 
        img={ sectionImage } 
        webp={ sectionImageWebp }
        alt='wired networking' />

      { /* <SlideViewer slides={ slides } /> */ }

    </SubPage>
  )
}
