import React, { useEffect } from 'react';
import '../styles/containers/contact.scss'
import contactHero from '../img/contact/amp-hero-contact.png'
import SubPageHero from '../components/SubPageHero'
import Offices from './contact/Offices'
import ContactInfo from './contact/ContactInfo'
import ContactForm from './contact/ContactForm'
import ScrollToTop from '../components/ScrollToTop'

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ ])
  
  return (
    <div className='contact'>
      <ScrollToTop />
      <SubPageHero
        title='Contact'
        image={ contactHero }
        copy={ false } />

      <div className='contact-body-wrapper'>
        <div className='contact-body'>
          <div className='contact-form'>
            <div className='contact-form-title'>
              Get in touch with us today
            </div>

            <ContactForm />
          </div>

          <ContactInfo />
        </div>
      </div>

      <Offices />
    </div>
  )
}
