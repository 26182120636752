import React from 'react'
import '../../styles/containers/landing/case-studies.scss'
import { motion } from 'framer-motion'
// import { useMediaQuery } from 'react-responsive'
import moaImage from '../../img/landing/case-study-moa.png'
import notreDameImage from '../../img/landing/case-study-notre-dame.png'
import tamuImage from '../../img/landing/case-study-tamu.png'
import targetCenterImage from '../../img/landing/case-study-target-center.png'

const cards = [
  {
    name: 'Mall of America',
    copy: 'When Mall of America decided to offer free Wi-Fi for its 40 million annual guests, it turned to AmpThink to design, build and manage the high density wireless network.',
    video: 'https://www.youtube.com/watch?v=qSZix3_yCGU',
    image: moaImage
  },
  {
    name: 'Notre Dame',
    copy: 'Fighting Irish football fans enjoyed free, high speed Wi-Fi thanks to the high density network designed and built by AmpThink.',
    video: 'https://www.youtube.com/watch?v=vf1UaLGXNc4',
    image: notreDameImage
  },
  {
    name: 'Texas A&M',
    copy: 'The stadium renovation project included the installation of an AmpThink-designed Wi-Fi network, which has consistently delivered record setting performance since its debut.',
    video: 'https://www.youtube.com/watch?v=wKC0nASHZD0',
    image: tamuImage
  },
  {
    name: 'Minnesota Timberwolves',
    copy: 'AmpThink implemented an expansive Wi-Fi experience and digital displays for the newly renovated arena.',
    video: 'https://www.youtube.com/watch?v=1jtfvd_qQak',
    image: targetCenterImage
  }
]

function Card({ card }) {
  // const isTablet = useMediaQuery({ query: '(max-width: 800px)' })

  return (
    <div className='case-studies-card'>
      <motion.div 
        className='case-studies-card-image-overlay'
        whileHover={{
          opacity: 0
        }} />

      <motion.img 
        className='case-studies-card-image' 
        src={ card.image } 
        alt=''
        whileHover={{
          zoom: 1.2,
          filter: 'grayscale(0)'
        }} />

      <div className='case-studies-card-title'>
        { card.name }
      </div>

      <div className='case-studies-card-copy'>
        { card.copy }
      </div>

      <motion.a 
        href={ card.video }
        className='case-studies-card-button'
        whileHover={{
          scale: 1.05
        }}
        whileTap={{
          borderRadius: '20px',
          scale: 0.9
        }}
        target='_blank'
        rel='noreferrer noopener'>
        Watch now
      </motion.a>
    </div>
  )
}

export default function CaseStudies() {
  return (
    <div className='case-studies'>
      { cards.map( card =>
        <Card 
          card={ card } 
          key={ card.name } />
      ) }
    </div>
  )
}