import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import '../styles/components/footer.scss'
import { useMediaQuery } from 'react-responsive'
import logo from '../img/components/logo-color-reversed.png'
import logoWebp from '../img/components/logo-color-reversed.webp'
import WidthLimiter from './WidthLimiter'
import SocialLinks from '../components/SocialLinks'
import Picture from '../components/Picture'

export default function Footer() {
  const isMobile = useMediaQuery({ query: '(max-width: 470px)' })

  return (
    <footer className='footer'>
    <WidthLimiter>
      <div className='footer-columns'>
        <div className='footer-column'>
          <Picture 
            styleClass='footer-logo' 
            img={ logo } 
            webp={ logoWebp }
            alt='logo' />

          <p className='footer-address'>
            3827 Dividend Dr, Garland, TX 75042<br/>
            <a href='tel:1.800.735.6310'>1.800.735.6310</a>
            <br/><br/>

            For More Information:<br/>
            <a href='mailto:info@ampthink.com'>info@ampthink.com</a>
            <br/><br/>

            For Support:
            <a href='mailto:support@ampthink.com'>support@ampthink.com</a>
          </p>
        </div>

        <div className='footer-column'>
          <div className='footer-heading'>
            WHAT WE DO
          </div>

          <Link to='/expertise'>
            Expertise
          </Link>

          <Link to='/specialties'>
            Specialties
          </Link>

          <Link to='/services'>
            Services
          </Link>
        </div>

        <div className='footer-column'>
          <div className='footer-heading'>
            COMPANY
          </div>

          <Link to='/contact'>
            Contact
          </Link>

          <Link to='/news'>
            News
          </Link>

          <a 
            href='https://apply.workable.com/ampthink/' 
            target='_blank' 
            rel='noreferrer noopener'>
            Careers
          </a>
        </div>

        <div className='footer-column'>
          <div className='footer-heading'>
            CONNECT
          </div>

          <SocialLinks />
        </div>
      </div>

      <div className='footer-copyright'>
        <div className='footer-copyright-section'>
          All material copyright ©{ format( new Date(), 'yyyy' ) } AmpThink
        </div>

        { !isMobile && ' · ' }

        <div className='footer-copyright-section'>
          All Rights Reserved License
        </div>

        { !isMobile && ' · ' }
        
        <div className='footer-copyright-section'>
          <Link to='/privacy'>Privacy Policy</Link>
        </div>
      </div>
      </WidthLimiter>
    </footer>
  )
}
