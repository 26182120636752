import React, { useEffect } from 'react'
import '../styles/containers/services.scss'
import SubPageHero from '../components/SubPageHero'
import ServiceRow from './services/ServiceRow'
import WidthLimiter from '../components/WidthLimiter'
import servicesHero from '../img/services/amp-hero-services.png'
import procurementImg from '../img/services/procurement.png'
import techscopeImg from '../img/services/techscope.png'
import stagingImg from '../img/services/staging-and-kitting.png'
import implementImg from '../img/services/implement.png'
import constructionImg from '../img/services/construction-networks.png'

const serviceRows = [
  {
    title: 'Technology Scope Coordination',
    copy: 'This platform can then be translated into a concrete listing of systems components (cables, switches, routers, access points, servers, etc.) which can be bid to technology OEMs as part of a packaged scope. This platform can then be translated into a concrete listing of systems components (which can be bid to technology OEMs as part of a packaged scope. During the development of the platform, the AmpThink team meets with the necessary parties to capture requirements, understand constraints, and discuss possible sponsorship strategies with new or existing venue technology partners.',
    image: 'techscope',
    imageImport: techscopeImg,
    imageRight: true
  },
  {
    title: 'Procurement',
    copy: 'Once the platform has been defined, AmpThink works with the owner on a transparent cost-plus basis to select and procure the required IT systems. Not to be confused with components that are integral to the scopes that are supported by IT systems, this effort is limited to common IT components shared across systems such as structured cabling, compute, network routing and switching, and internet access. ',
    image: 'procurement',
    imageImport: procurementImg,
    imageRight: false
  },
  {
    title: 'Staging & Kitting',
    copy: 'Integral to reducing costs and meeting project timelines is the ability to deliver “installation ready assemblies” to the field. Informed by lean construction methodologies, AmpThink delivers the procured technology systems through our Garland, Texas logistics center. The team in our Garland facility processes thousands of IT assets every month, lowering labor costs and driving to very high levels of quality. Supported by our manufacturing operations, AmpThink can design and build supporting systems (for example enclosures and cable assemblies).',
    image: 'staging',
    imageImport: stagingImg,
    imageRight: true
  },
  {
    title: 'Implementation and Optimization',
    copy: 'Once an OEM or OEMs have been selected, AmpThink is on hand to implement and optimize the system. Our experienced team of project managers (“Client Advocates”) will manage the delivery and installation of the technology scope and coordinate the onboarding of the other technology dependent scopes. Our Client Advocates are supported by a team of engineers who will handle the implementation details and train and support the owner post implementation.',
    image: 'implement',
    imageImport: implementImg,
    imageRight: false
  },
  {
    title: 'Construction Networks',
    copy: 'In many cases, it is impractical to deploy the final venue network/compute infrastructure in time to support building system commissioning activities for systems that come on-line early in the life of a building. Often, IT rooms and structured cabling systems are incomplete. And in some cases, lead times on networking or compute hardware are such that they do not arrive until after commissioning has begun. This opens the door for the deployment of island solutions out of necessity. To address this issue, AmpThink has developed an extensive inventory of “construction networking equipment” which can be deployed during the construction process to meet needs that occur before the deployment of the final networking and compute solutions. This infrastructure includes routers, firewalls, switches, servers, and Wi-Fi access points. ',
    image: 'construction',
    imageImport: constructionImg,
    imageRight: true
  },
]

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ ])

  return (
    <div className='services'>
      <SubPageHero
        title='Services'
        image={ servicesHero }
        copy='Rethinking construction and deploying innovative technologies is driven by the specifications delivered by the projects design team. Functioning as the MTI, AmpThink works with the owner to unify the technology dependencies of all of the building systems into a platform.' />
      
      <WidthLimiter>
        <div className='services-body'>
          { serviceRows.map((row, index) => 
            <ServiceRow
              key={ row.image }
              index={ index }
              image={ row.image }
              imageImport={ row.imageImport }
              title={ row.title }
              copy={ row.copy }
              imageRight={ row.imageRight } />
          ) }
        </div>
      </WidthLimiter>
    </div>
  )
}
