import React from "react";
import "../../styles/containers/contact/offices.scss";
import dallasOffice from "../../img/contact/dallas.png";
import OfficeInfo from "./OfficeInfo";
import OfficeImage from "./OfficeImage";
import WidthLimiter from "../../components/WidthLimiter";
import laOffice from "../../img/contact/los-angeles.jpg";
import orlandoOffice from "../../img/contact/orlando.jpg";
import eerieOffice from "../../img/contact/eerie.jpg";

export default function Offices() {
  return (
    <WidthLimiter>
      <div className="contact-offices">
        <div className="contact-offices-heading">Offices</div>

        <div className="contact-offices-row">
          <div className="contact-offices-dallas">
            <OfficeImage image={dallasOffice} />

            <OfficeInfo title="DALLAS HQ">
              3827 Dividend Dr.
              <br />
              Garland, TX 75042
            </OfficeInfo>
          </div>

          <div className="contact-offices-halfs">
            {/* <div className='contact-offices-half'>
            <OfficeImage image={dallasOffice} />

            <OfficeInfo
              title='DALLAS HQ'>
              3827 Dividend Dr.<br />
              Garland, TX 75042
            </OfficeInfo>
          </div> */}

            <div className="contact-offices-half">
              <OfficeImage image={laOffice} />

              <OfficeInfo title="LOS ANGELES">
                SoFi Stadium <br />
                1001 Stadium Dr. <br />
                Inglewood, CA 90301
              </OfficeInfo>
            </div>

            <div className="contact-offices-half">
              <OfficeImage image={orlandoOffice} />

              <OfficeInfo title="ORLANDO">
                Kia Center Arena <br />
                400 W Church St. Orlando, FL 32801
              </OfficeInfo>
            </div>

            <div className="contact-offices-half">
              <OfficeImage image={eerieOffice} />

              <OfficeInfo title="FORT ERIE">
                2 Lewis Street Unit 16, <br />
                Fort Erie, Ontario L2A2P7 Canada
              </OfficeInfo>
            </div>
          </div>
        </div>
      </div>
    </WidthLimiter>
  );
}
