import React, { useEffect } from 'react'
import '../styles/containers/specialties.scss'
import { Route, Switch, NavLink, Redirect, useLocation } from 'react-router-dom'
import specialtiesHero from '../img/specialties/amp-hero-specialties.png'
import SubPageHero from '../components/SubPageHero'
import Support from './specialties/Support'
import WiredNetworking from './specialties/WiredNetworking'
import WirelessNetworking from './specialties/WirelessNetworking'
import IPTV from './specialties/IPTV'
import IOT from './specialties/IOT'
// import DAS from './specialties/DAS'
// import Telephony from './specialties/Telephony'
import WidthLimiter from '../components/WidthLimiter'

const specialtiesRoutes = [
  { title: 'Support', path: 'support', comp: Support },
  { title: 'Wired Networking', path: 'wired-networking', comp: WiredNetworking },
  { title: 'Wireless Networking', path: 'wireless-networking', comp: WirelessNetworking },
  { title: 'IPTV', path: 'iptv', comp: IPTV },
  { title: 'IOT', path: 'iot', comp: IOT },
  // { title: 'DAS', path: 'das', comp: DAS },
  // { title: 'Telephony', path: 'telephony', comp: Telephony },
]

export default function Specialties() {
  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ ])

  return (
    <div className='specialties'>
      <SubPageHero
        title='Specialties'
        image={ specialtiesHero }
        copy='AmpThink offers unmatched expertise in the design, and deployment of technology services in support of the construction, and renovation of large public venues and multi-use developments. It is understood that the vision for these venues provide robust connectivity and communications systems for an enhanced fan and customer experience.' />
    
      <WidthLimiter>
      <div className='specialties-body'>
        <div className='mini-nav'>
          { specialtiesRoutes.map(option => 
            <NavLink
              key={ option.path }
              to={ `/specialties/${ option.path }` }
              className='mini-nav-link'>
            
              { option.title }
            </NavLink>
          ) }
        </div>

        <div className='specialties-page'>
          <Switch location={ location }>
            <Route 
              exact 
              path='/specialties'>
              <Redirect to='/specialties/support' />
            </Route>

            { specialtiesRoutes.map(option => 
              <Route
                exact
                key={ option.path }
                path={ `/specialties/${ option.path }` }
                component={ option.comp } />
            ) }
          </Switch>
        </div>
      </div>
      </WidthLimiter>
    </div>
  )
}
