import React from 'react'
import '../styles/components/social-share-button.scss'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import Picture from './Picture'

const variants = {
  static: {
    background: '#19444b',
    color: '#fff'
  },
  hovered: {
    background: '#1a2127',
    color: '#aaa',
  },
  tapped: {
    background: '#de592e',
    color: '#fff',
    borderRadius: '30px',
    scale: 0.9
  }
}

export default function SocialShareButton({ text, icon, iconWebp }) {
  return (
    <motion.div
      variants={ variants }
      whileHover='hovered'
      whileTap='tapped'
      initial='static'
      className='social-share-button'>
      { text }
      { icon && <Picture img={ icon } webp={ iconWebp } alt='social-icon' /> }
    </motion.div>
  )
}

SocialShareButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconWebp: PropTypes.string.isRequired,
}
