import React from 'react'
import PropTypes from 'prop-types'

export default function Picture({ webp, img, alt = '', styleClass = '', clickHandler }) {
  return (
    <picture onClick={ clickHandler } className={ styleClass }>
      <source srcSet={ webp } type='image/webp' />
      <img src={ img } alt={ alt } />
    </picture>
  )
}

Picture.propTypes = {
  webp: PropTypes.string,
  img: PropTypes.string.isRequired,
  alt: PropTypes.string,
  styleClass: PropTypes.string,
  clickHandler: PropTypes.func,
}