import React, { useEffect } from 'react'
import '../styles/containers/privacy.scss'
import WidthLimiter from '../components/WidthLimiter'

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ ])

  return (
    <WidthLimiter>
      <div className='privacy'>
        <h2>AMPTHINK PRIVACY POLICY</h2>

        <p>
          AmpThink, LLC (“AmpThink”) is a corporation organized under the laws of the State of Texas and located at 3827 Dividend Drive, Garland, Texas 75042. AmpThink is committed to protecting the privacy of data it collects from individuals and user devices that interact with AmpThink systems. This Privacy Policy covers AmpThink’s website and AmpThink’s practices for handling personal information provided to AmpThink over the Internet by individuals  including employees, job applicants, customers, etc. AmpThink will collect, store, and user personally identifiable information only in compliance with applicable federal and state law.
        </p>

        <h3>
          YOUR FURTHER USE OF THE AMPTHINK WEBSITE CONSTITUTES YOUR ACCEPTANCE OF AND CONSENT TO THIS POLICY.
        </h3>

        <p>
          AmpThink reserves the right to change this Policy at any time. Please check this page periodically for changes. Your future provision of private information by way of the AmpThink website following changes to this Policy will mean that you accept those changes.
        </p>

        <h3>Information Collected by AmpThink</h3>
        
        <ul>
          <li>
            Information Provided Directly To AmpThink. AmpThink may collect information you provide directly to AmpThink, through the AmpThink website, by mail or delivery services, or in person. For example, AmpThink may collect your name, e-mail address, or other contact information when you access AmpThink services, when you fill out any AmpThink forms, when you apply for a job, or when you submit questions or comments.
          </li>

          <li>
            Information Automatically Collected When You Visit The AmpThink Website. When you visit the AmpThink website, AmpThink may automatically gather certain technical and usage information. Such information can include data on when, how often, and for how long you use the website, and server log data such as a computer’s Internet Protocol (IP) address, browser type, device type, operating system and version, or a webpage you were visiting before you navigated to the AmpThink website. Technical information may also include cookies, which are small text files containing a string of alphanumeric characters that are downloaded by your web browser when you visit a website. Cookies may be used to remember your preferences and to assist AmpThink in measuring and analyzing utilization of the AmpThink website. Like many online services, AmpThink may use both session cookies and persistent cookies when you access the AmpThink website. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the website. Persistent cookies can be removed. Please review your web browser’s documentation to learn the best way to modify your cookie settings. 
          </li>

          <li>
            Information Automatically Collected By Third Parties. From time to time, AmpThink may engage third-party web analytics services providers, such as Google Analytics, which may use cookies to provide analytics services for AmpThink. Such cookies may be removed using your web browser’s settings.
          </li>
        </ul>

        <h3>How AmpThink Uses Collected Information</h3>

        <p>
          The AmpThink website only collects personally identifiable information from you if you choose to provide this information to AmpThink. AmpThink is the sole owner of the information collected via the AmpThink website, and AmpThink only has access to information that you voluntarily provide to us via e-mail or other direct contact from you through the AmpThink website. Only authorized AmpThink employees have the ability to access of view this data.
        </p>

        <p>
          AmpThink uses information provided by you solely for the specific purpose for which you provided the information. Depending on the purpose for which you provided the information, you may provide us with certain personally identifiable information (PII) such as first and last name, previously used names, social security number, date of birth, home address, or other physical address, e-mail address (for contact purposes), telephone number, employment information, schools attended, licenses held, and other information as required to process your request. Other information may include inquiries you make through the AmpThink website.
        </p>

        <p>
          AmpThink does not engage in the practice of selling, renting, or leasing your personally identifiable information to others. AmpThink may disclose personal information when audit requirements are necessary or when required by law or in the good faith belief to obey the law or comply with a legal obligation.
        </p>

        <h3>Sharing And Transfer of Information</h3>

        <p>
          AmpThink may share the information we collect from you and maintain about you as follows:
        </p>

        <ul>
          <li>
            Legal Requirement. AmpThink will use and disclose information where AmpThink, in good faith, believes that the law or legal process (such as a court order, search warrant, or subpoena) requires AmpThink to do so or in other circumstances where AmpThink believes it is necessary to protect the rights or property of AmpThink, AmpThink website users, or third parties.
          </li>

          <li>
            Business Transfer. In the event of a merger, sale of capital stock or assets, reorganization, consolidation, or similar transaction involving AmpThink, or in contemplation of such a transaction (e.g., due diligence), the information AmpThink possesses may be transferred as a corporate asset to the acquiring entity.
          </li>

          <li>
            Analytics. From time to time, AmpThink may use a third-party analytics services for purposes of analyzing usage of AmpThink’s websites and/or services. In such instances, your information may be used to generically identify activities undertaken with respect to AmpThink resources. In no event, however, will personally identifying information be disclosed in such circumstances.
          </li>
        </ul>

        <h3>Updating And Correcting Your Information</h3>

        <p>
          Applicants that apply for job positions posted by AmpThink by way of the AmpThink website should promptly update their contact information if it changes or if it is inaccurate. Requests to change contact information (e-mail address, phone number, mailing address) or other information (such as Social Security information) may be made in person by calling AmpThink at 800-735-6310. Applicants may also request deletion of personal data by contacting AmpThink at the phone number listed above.
        </p>

        <h3>This Privacy Policy Only Applies to AmpThink</h3>

        <p>
          It is noted that this Privacy Policy does not apply to third-party websites that may be accessed by way of a link on the AmpThink website. The provisions of this Privacy Policy only applies to AmpThink. Please review terms and conditions of privacy policies maintained by third-parties before providing personally identifying information to them.
        </p>

        <h3>Protection Of Information</h3>

        <p>
          AmpThink maintains safeguards to protect the security of information AmpThink maintains about you. AmpThink may use encryption, passwords, and physical security measures to help protect your information against unauthorized access and disclosure. However, it is noted that no security measure is 100% effective at all times and AmpThink cannot guarantee your information will not be collected, disclosed, and/or used by others without your permission. You should take steps to protect against unauthorized access to your passwords, phone numbers, and computers by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, keeping your login information private, and not recycling passwords from other websites or accounts. AmpThink is not responsible for the unauthorized use of your information nor for any lost, stolen, or compromised passwords, or for any activity on your account via unauthorized password activity. AMPTHINK EXPRESSLY DISCLAIMS ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO ENSURING, GUARANTEEING, OR OTHERWISE OFFERING ANY DEFINITIVE PROMISE OR SECURITY IN CONNECTION WITH YOUR INFORMATION OR TECHNICAL INFORMATION.
        </p>

        <p>
          AmpThink stores information on servers located in the United States. At times, AmpThink may use partners or affiliates located in other countries besides the United States to process your information. By providing information to AmpThink, you consent to the transfer of information outside of the United States.
        </p>
      </div>
    </WidthLimiter>
  )
}