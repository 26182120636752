import React from 'react';
import { motion } from 'framer-motion';
import fbIcon from '../img/components/sm-facebook.svg';
import twitterIcon from '../img/components/sm-twitter.svg';
import linkedinIcon from '../img/components/sm-linkedin.svg';

const variants = {
  hovered: { 
    scale: 1.1
  },
  tapped: {
    scale: 0.9
  }
}

export default function SocialLinks() {
  return (
    <div className='social'>
      <motion.a 
        variants={ variants }
        whileHover='hovered'
        whileTap='tapped'
        href='https://www.facebook.com/AmpThink/' 
        target='_blank' 
        rel='noopener noreferrer'>
        <motion.img src={ fbIcon } alt='facebook' />
      </motion.a>

      <motion.a 
        variants={ variants }
        whileHover='hovered'
        whileTap='tapped'
        href='https://twitter.com/ampthink' 
        target='_blank' 
        rel='noopener noreferrer'>
        <motion.img src={ twitterIcon } alt='twitter' />
      </motion.a>

      <motion.a 
        variants={ variants }
        whileHover='hovered'
        whileTap='tapped'
        href='https://www.linkedin.com/company/ampthink' 
        target='_blank' 
        rel='noopener noreferrer'>
        <motion.img src={ linkedinIcon } alt='linkedin' />
      </motion.a>
    </div>
  )
}
