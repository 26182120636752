import React, { useState, useEffect } from 'react'
import is from 'is_js'
import axios from 'axios'
import '../styles/containers/news.scss'
import SubPageHero from '../components/SubPageHero'
import { motion } from 'framer-motion'
import Press from '../components/Press'
import expertiseHero from '../img/news/amp-hero-news.png'
import { usePosts } from '../hooks/use-posts'
import WidthLimiter from '../components/WidthLimiter'
import ScrollToTop from '../components/ScrollToTop'

const variants = {
  static: {
    background: 'rgba(0, 0, 0, 0)',
    color: '#aaa'
  },
  hovered: {
    background: '#aaa',
    color: '#f7f7f7',
  },
  tapped: {
    background: '#de592e',
    borderRadius: '30px',
    color: '#f7f7f7',
    scale: 0.9
  }
}

// const proxy = process.env.NODE_ENV === 'test' ? '' : 'https://protected-shore-15426.herokuapp.com/'
const proxy = ''

export default function News() {
  const [ loading, setLoading ] = useState(true)
  const [ loadedPosts, setLoadedPosts ] = useState()
  const [ clickCount, setClickCount ] = useState(1)
  const { data: posts } = usePosts()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ ])

  useEffect(() => {
    if (clickCount === 1 && is.array(posts)) {
      setLoadedPosts(posts)
      setLoading(false)
    }
  }, [ posts, clickCount ])

  const handleLoad = async () => {
    const pageCount = clickCount + 1

    setLoading(true)

    let request = await axios.get(`${ proxy }https://blog.ampthink.com/wp-json/wp/v2/posts?per_page=${ pageCount * 3 }`)

    if (is.array(request.data)) {
      setClickCount(pageCount)
      setLoadedPosts(request.data)
      setLoading(false)
    }
  }

  return (
    <div className='news'>
      <ScrollToTop />
      <SubPageHero
        title='News'
        image={ expertiseHero }
        copy={ false } />

      <Press posts={ loadedPosts } />

      <WidthLimiter>
        <div className='landing-button-row'>
          <div onClick={ handleLoad } className='landing-button-link' style={{ cursor: 'pointer' }}>
            <motion.div
              variants={ variants }
              whileHover='hovered'
              whileTap='tapped'
              initial='static'
              className='landing-button'>
              { loading ? 'LOADING MORE' : 'LOAD MORE' }
            </motion.div>
          </div>
        </div>
      </WidthLimiter>
    </div>
  )
}
