import React from 'react'
import '../../styles/containers/landing/landing-button.scss'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import WidthLimiter from '../../components/WidthLimiter'

const variants = {
  static: {
    background: 'rgba(0, 0, 0, 0)',
    color: '#aaa'
  },
  hovered: {
    background: '#aaa',
    color: '#f7f7f7',
  },
  tapped: {
    background: '#de592e',
    borderRadius: '30px',
    color: '#f7f7f7',
    scale: 0.9
  }
}

export default function LandingButton({ text, path, external }) {
  if (path) {
    return (
      <WidthLimiter>
        <div className='landing-button-row'>
          <Link className='landing-button-link' to={ path }>
            <motion.div
              variants={ variants }
              whileHover='hovered'
              whileTap='tapped'
              initial='static'
              className='landing-button'>
              { text }
            </motion.div>
          </Link>
        </div>
      </WidthLimiter>
    )
  } else {
    return (
      <WidthLimiter>
        <div className='landing-button-row'>
          <a 
            className='landing-button-link' 
            href={ external } 
            target='_blank' 
            rel='noopener noreferrer'>
            <motion.div
              variants={ variants }
              whileHover='hovered'
              whileTap='tapped'
              initial='static'
              className='landing-button'>
              { text }
            </motion.div>
          </a>
        </div>
      </WidthLimiter>
    )
  }
}

