import React, { useEffect } from 'react'
import '../styles/containers/support.scss'
import SubPageHero from '../components/SubPageHero'
import contactHero from '../img/contact/amp-hero-contact.png'
import WidthLimiter from '../components/WidthLimiter'

export default function Support() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `window.ZohoHCAsap=window.ZohoHCAsap||function(a,b){ZohoHCAsap[a]=b;};(function(){var d=document;var s=d.createElement("script");s.type="text/javascript";s.defer=true;s.src="https://desk.zoho.com/portal/api/web/inapp/474210000001551021?orgId=708377190";d.getElementsByTagName("head")[0].appendChild(s);})();`;
    
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className='support'>
      <SubPageHero
        title='Support'
        image={ contactHero }
        copy={ false } />
      
      <WidthLimiter>
        <div className='support-body'>
          <div className='support-heading'>
            Support
          </div>
        </div>
      </WidthLimiter>
    </div>
  )
}