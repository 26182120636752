import React, { useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import MenuItem from './MenuItem';
import SocialLinks from './SocialLinks';

const variants = {
  open: {
    display: 'block',
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    display: 'none',
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export default function NavigationOverlay({ navLinks, menuOpen, handleToggle }) {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (menuOpen) {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalStyle
    }
    
    // Re-enable scrolling when component unmounts
    return () => document.body.style.overflow = originalStyle;
  }, [ menuOpen ]);

  return (
    <motion.ul
      variants={ variants }>
      { navLinks.map((link, index) => 
        <MenuItem 
          index={ index } 
          key={ index } 
          link={ link }
          handleToggle={ handleToggle }  />
      ) }

      <motion.li>
        <SocialLinks />
      </motion.li>
    </motion.ul>
  );
}
