import React, { useRef } from 'react';
import '../../styles/containers/expertise/growth.scss';
import useVisibilitySensor from '@rooks/use-visibility-sensor';
// import GrowthItem from './GrowthItem';
import CountUp from './CountUp';
import VenueDoughnut from './VenueDoughnut';
import locationIcon from '../../img/expertise/icon-location.png';
import storeIcon from '../../img/expertise/icon-store.png';
import wifiIcon from '../../img/expertise/icon-wifi.png';
import boxIcon from '../../img/expertise/icon-box.png';
import cableIcon from '../../img/expertise/icon-cable.png';

// const growthItems = [
//   {
//     title: 'Sports & Entertainment Venues',
//     amount: '75+',
//     progressPercent: 100
//   },
//   {
//     title: 'Pro U.S. Sports Stadiums & Arenas',
//     amount: '65+',
//     progressPercent: 90
//   },
//   {
//     title: 'D1 College Sports Stadiums & Arenas',
//     amount: '12+',
//     progressPercent: 20
//   },
//   {
//     title: 'International Venues',
//     amount: '7+',
//     progressPercent: 10
//   }
// ];

const counts = [
  {
    number: 130,
    symbol: '+',
    copy: 'Warehouse Projects Since 2011',
    icon: locationIcon
  },
  {
    number: 1880,
    symbol: '+',
    copy: 'Retail Store Projects Completed',
    icon: storeIcon
  },
  {
    number: 20,
    symbol: 'k+',
    copy: 'Enclosures Built',
    icon: wifiIcon
  },
  {
    number: 395,
    symbol: 'k+',
    copy: 'Physical Assets Depoloyed',
    icon: boxIcon
  },
  {
    number: 4,
    symbol: 'M+',
    copy: 'Ft. of Cable Mfg in AmpThink Facility',
    icon: cableIcon
  }
]

export default function Growth() {
  const chartNode = useRef(null);
  const statsNode = useRef(null);
  const { isVisible: chartVisible } = useVisibilitySensor(chartNode, {
    resizeCheck: true,
    scrollDebounce: 100
  });

  const { isVisible: statsVisible } = useVisibilitySensor(statsNode, {
    resizeCheck: true,
    scrollDebounce: 100
  });

  return (
    <div className='growth-wrapper'>
      <div className='growth'>
        <div className='growth-heading'>
          growth
        </div>

        <div className='growth-body'>
          <div className='growth-title'>
            <h2>AmpThink growth in the venue space</h2>
          </div>

          <div className='growth-chart' ref={ chartNode }>
            <VenueDoughnut chartVisible={ chartVisible } />
          </div>

          <div className='growth-stats-container' ref={ statsNode }>
            { counts.map((count, index) => 
              <CountUp
                key={ index }
                index={ index }
                number={ count.number }
                symbol={ count.symbol }
                copy={ count.copy }
                icon={ count.icon }
                shouldAnimate={ statsVisible } />
            ) }
          </div>
        </div>
      </div>
    </div>
  )
}
