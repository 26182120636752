import React from 'react'

export default function OfficeImage({ image }) {
  return (
    <div
      className='contact-offices-image' 
      style={{ backgroundImage: `url(${ image })` }} >
    </div>
  )
}
