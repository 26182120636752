import { useQuery } from 'react-query';
import axios from 'axios';
import adapter from 'axios/lib/adapters/http';
import is from 'is_js';

// const proxy = process.env.NODE_ENV === 'test' ? '' : 'https://protected-shore-15426.herokuapp.com/';
const proxy = ''

export const usePost = (id) => {
  return useQuery([ 'get-post', id ], () => axios.get(`${ proxy }https://blog.ampthink.com/wp-json/wp/v2/posts/${ id }`, { adapter })
    .then(res => res.data)
    .catch(err => console.error(err)),
    {
      enabled: is.existy(id)
    }
  );
}
