import React from 'react'
import SubPage from './SubPage'
import QuadSection from './QuadSection'
import Picture from '../../components/Picture'
import sectionImage from '../../img/specialties/specialties-iptv.png'
import sectionImageWebp from '../../img/specialties/specialties-iptv.webp'
import leandeliveryIcon from '../../img/specialties/icon-leandelivery.png'
import expertiseIcon from '../../img/specialties/icon-expertise.png'
import flexibleIcon from '../../img/specialties/icon-flexible.png'
import roiIcon from '../../img/specialties/icon-roi.png'

const quadSections = [
  {
    title: 'LEAN DELIVERY',
    copy: 'Leveraging our proven Wi-Fi deployment strategy, we’ve reduced deployment times and lowered costs for ITPV delivery',
    icon: leandeliveryIcon
  },
  {
    title: 'UNPARALLELED EXPERTISE',
    copy: 'Dedicated team of experts in network transported voice and video, content creation, and monetization',
    icon: expertiseIcon
  },
  {
    title: 'FLEXIBLE DEPLOYMENTS',
    copy: 'Projects range from system upgrades to full system deployments for venues features over 2,500 end points',
    icon: flexibleIcon
  },
  {
    title: 'FULL IMPACT ROI',
    copy: 'Maximize your IPTV investment with our specialized applications and  that deliver revenue and cut costs',
    icon: roiIcon
  },
]

export default function IPTV() {
  return (
    <SubPage>
      <div className='specialties-page-header'>
        IPTV
      </div>

      <div className='specialties-page-header-below'>
        Our investment in IPTV began in 2014 with a network refresh at the Mall of America. A relatively 
        small IPTV initiative incorporating about 400 displays, the Mall was a perfect training ground 
        for our team who tackled the integration of IPTV into the overall Mall network. During this 
        engagement, the AmpThink team applied the same discipline that had been applied to Wi-Fi, basing 
        our deployment strategy on staging, automation, kitting, and labeling. Our lean approach to 
        IPTV delivery has resulted in reduced deployment times and lower costs. 
      </div>

      <div className='specialties-quad'>
        { quadSections.map((section, index) => 
          <QuadSection 
            key={ index } 
            section={ section } 
            index={ index } />
        ) }
      </div>

      <Picture 
        styleClass='specialties-page-image' 
        img={ sectionImage } 
        webp={ sectionImageWebp }
        alt='City Wi-Fi' />
    </SubPage>
  )
}
