import React, { useEffect } from 'react'
import '../styles/components/nav-bar.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import MobileNav from './MobileNav'
import WidthLimiter from './WidthLimiter'
import Picture from './Picture';
import logo from '../img/components/logo.png'
import logoWebp from '../img/components/logo.webp'
import CrossSiteHeader from './CrossSiteHeader'

const navLinks = [
  {
    id: 'nav-link-expertise',
    path: '/expertise',
    text: 'WHO WE ARE'
  },
  {
    id: 'nav-link-specialties',
    path: '/specialties',
    text: 'WHAT WE DO'
  },
  {
    id: 'nav-link-services',
    path: '/services',
    text: 'HOW WE DO IT'
  },
  {
    id: 'nav-link-news',
    path: '/news',
    text: 'NEWS'
  },
  {
    id: 'nav-link-contact',
    path: '/contact',
    text: 'CONTACT'
  },
]

export default function NavBar() {
  const showMobileNav = useMediaQuery({ query: '(max-width: 800px)' })

  useEffect(() => {
    AOS.init({
      miror: false,
    })
  }, [])

  useEffect(() => {
    let scrollRef = 0

    window.addEventListener('scroll', function () {
      scrollRef <= 10 ? scrollRef++ : AOS.refresh()
    })
  })

  return (
    <nav>
      <CrossSiteHeader />
      
      <WidthLimiter>
        <div className='nav-bar'>
          <NavLink
            data-testid='nav-link-landing'
            className='nav-logo'
            to='/'>
            <Picture 
              img={ logo } 
              webp={ logoWebp }
              alt='logo' />
          </NavLink>

          { showMobileNav ? (
            <MobileNav navLinks={ navLinks } />
          ) : (
            <div className='nav-bar-links'>
              { navLinks.map(link =>
                <NavLink 
                  key={ link.id }
                  data-testid={ link.id }
                  className='nav-link'
                  to={ link.path }>
                  { link.text }
                </NavLink>
              ) }
            </div>
          ) }
        </div>
      </WidthLimiter>
    </nav>
  )
}
